var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"server-items-length":_vm.maxRecords,"options":_vm.options,"headers":_vm.headers,"items":_vm.logsData,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.app_build",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.app_build || '-')+" ")])],1)]}},{key:"item.build_output.slug",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getSlug(item))+" ")])],1)]}},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.branch || '-')+" ")])],1)]}},{key:"item.fast_lane_action",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.fast_lane_action || '-')+" ")])],1)]}},{key:"item.workflow_id",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.workflow_id || '-')+" ")])],1)]}},{key:"item.test",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(item.test ? 'Sim' : 'Não')+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getDate(item.created_at) || '-')+" ")])],1)]}},{key:"item.details.status",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{attrs:{"small":"","color":_vm.getStatusLabel(item.details.status).color}},[_vm._v(" fiber_manual_record ")]),_vm._v(" "+_vm._s(_vm.getStatusLabel(item.details.status).text)+" ")],1)],1)]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openDetails(item)}}},[_c('v-list-item-title',[_vm._v("Detalhes")])],1),(item.build_output)?_c('v-list-item',{on:{"click":function($event){return _vm.redirect(item.build_output.build_url)}}},[_c('v-list-item-title',[_vm._v("Log Bitrise")])],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":!item.account_id,"to":("/accounts/" + (item.account_id) + "/app-config")}},[_c('v-list-item-title',[_vm._v("Configurações do app")])],1)],1)],1)]}}])}),_c('build-details-modal',{attrs:{"dialog":_vm.detailsDialog,"build":_vm.currentBuild},on:{"update:dialog":function($event){_vm.detailsDialog=$event},"update:build":function($event){_vm.currentBuild=$event},"close":_vm.closeDetailsDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }