<template>
  <div>
    <v-data-table
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
      :server-items-length="maxRecords"
      :options.sync="options"
      :headers="headers"
      :items="logsData"
      :search="search"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.app_build="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.app_build || '-' }}
          </v-layout>
        </v-flex>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.build_output.slug="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ getSlug(item) }}
          </v-layout>
        </v-flex>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.branch="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.branch || '-' }}
          </v-layout>
        </v-flex>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.fast_lane_action="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.fast_lane_action || '-' }}
          </v-layout>
        </v-flex>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.workflow_id="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.workflow_id || '-' }}
          </v-layout>
        </v-flex>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.test="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.test ? 'Sim' : 'Não' }}
          </v-layout>
        </v-flex>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.created_at="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ getDate(item.created_at) || '-' }}
          </v-layout>
        </v-flex>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.details.status="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            <v-icon small :color="getStatusLabel(item.details.status).color"> fiber_manual_record </v-icon>
            {{ getStatusLabel(item.details.status).text }}
          </v-layout>
        </v-flex>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.options="{ item }">
        <v-menu :close-on-content-click="false">
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openDetails(item)">
              <v-list-item-title>Detalhes</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.build_output" @click="redirect(item.build_output.build_url)">
              <v-list-item-title>Log Bitrise</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="!item.account_id" :to="`/accounts/${item.account_id}/app-config`">
              <v-list-item-title>Configurações do app</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <build-details-modal :dialog.sync="detailsDialog" :build.sync="currentBuild" @close="closeDetailsDialog" />
  </div>
</template>

<script>
import { QUERY_GET_APP_BUILD_LOGS } from '@/modules/app/graphql/index.js'

export default {
  components: {
    BuildDetailsModal: () => import('@/modules/app/views/components/BuildDetailsModal.vue')
  },
  props: {
    search: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    logsData: [],
    options: {},
    maxRecords: 0,
    loadingAction: false,
    detailsDialog: false,
    currentBuild: null,
    fastlaneAction: ''
  }),
  computed: {
    headers() {
      return [
        {
          text: 'App',
          value: 'app_build',
          align: 'center'
        },
        {
          text: 'Slug',
          value: 'build_output.build_slug',
          align: 'center'
        },
        {
          text: 'Branch',
          value: 'branch',
          align: 'center'
        },
        {
          text: 'Comando',
          value: 'fast_lane_action',
          align: 'center'
        },
        {
          text: 'Workflow',
          value: 'workflow_id',
          align: 'center'
        },
        {
          text: 'Teste',
          value: 'test',
          align: 'center'
        },
        {
          text: 'Criado em',
          value: 'created_at',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'details.status',
          align: 'center',
          sortable: false
        },
        {
          value: 'options',
          align: 'center'
        }
      ]
    },
    loading() {
      return this.$apollo.loading || this.loadingAction
    }
  },
  apollo: {
    appBuildLogs: {
      query: QUERY_GET_APP_BUILD_LOGS,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          this.fastlaneAction ? { fast_lane_action: this.fastlaneAction } : null,
          { page: this.options.page || 1 },
          { pageSize: this.options.itemsPerPage || 10 },
          { search: this.search || null },
          { sort: this.options.sortBy || ['created_at'] },
          { sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC')) }
        )
      },
      update({ appBuildLogs: { count, data } }) {
        this.maxRecords = count
        this.logsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['app_build']
        }
      }
    }
  },
  watch: {
    refresh(value) {
      if (value) {
        this.$apollo.queries.appBuildLogs.refetch()
        setTimeout(() => {
          this.$emit('update:refresh', false)
        }, 1000)
      }
    }
  },
  methods: {
    getDate(date) {
      return this.$moment(new Date(date)).format('YYYY-MM-DD, HH:mm:ss')
    },
    getSlug(item) {
      return item?.build_output?.slug || '-'
    },
    getStatusLabel(value) {
      const mapStatus = {
        '0': {
          text: 'Rodando',
          color: '#339af0'
        }, // not_finished
        '1': { text: 'Sucesso', color: '#07DD18' }, // successful
        '2': { text: 'Falhou', color: '#FF5252' }, // failed
        '3': { text: 'Abortado com falha', color: '#cc5de8' }, // aborted_with_failure
        '4': { text: 'Abortado com sucesso', color: '#FFF176' } // aborted_with_success
      }

      return mapStatus[value] || { text: 'Não identificado', color: '' }
    },
    redirect(url) {
      return window.open(url, '_blank')
    },
    openDetails(build) {
      this.currentBuild = build
      this.detailsDialog = true
    },
    closeDetailsDialog() {
      this.currentBuild = null
      this.$emit('update:refresh', true)
    },
    copySlug(slug) {
      navigator.clipboard.writeText(slug)
      this.$snackbar({ message: 'Build slug copiado', snackbarColor: '#2E7D32' })
    }
  }
}
</script>
